import { render, staticRenderFns } from "./batchDealBlock.vue?vue&type=template&id=e2af9f36&scoped=true&"
import script from "./batchDealBlock.vue?vue&type=script&lang=js&"
export * from "./batchDealBlock.vue?vue&type=script&lang=js&"
import style0 from "./batchDealBlock.vue?vue&type=style&index=0&id=e2af9f36&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2af9f36",
  null
  
)

export default component.exports