<template>
  <fm-modal title="采购申请" v-model="modal" :mask-closable="false" width="800" v-if="modal">
    <div class="p-detial-from">
      <div class="left-a">
        <fm-title titleText="采购方式"></fm-title>
        <fm-form label-align="left">
          <fm-form-item label="职能科室" v-if="orgList.length > 1">
            <fm-select absolute filterable :clearable="true" v-model="formData.busOrgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="类型">
            <fm-select @change="formData.method = null" absolute filterable :clearable="true" v-model="formData.type">
              <fm-option v-for="item in typeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="采购方式">
            <fm-select absolute filterable :clearable="true" v-model="formData.method">
              <fm-option v-for="item in formData.type ? methodTypeList[formData.type] || [] : []" :key="item" :value="item" :label="item"></fm-option>
            </fm-select>
          </fm-form-item>
        </fm-form>
        <fm-title titleText="采购明细"></fm-title>
        <DetailList ref="detailList" :purchase-batch-detail-list="purchaseBatchDetailList" :detail-list="formData.detailList"></DetailList>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">保存</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
    </div>
  </fm-modal>
</template>

<script>
import {
  purchaseMethodBatchDetailsRequest
} from '../../../../api'

// import purchaseBatchDetailInfo from './purchaseBatchDetailInfoAdd'

import DetailList from './detailList'

export default {
  components: {
    DetailList
  },
  props: {
    data: { type: Object, defualt: () => null },
    orgList: { type: Array, defualt: () => [] },
    typeList: { type: Array, defualt: () => [] },
    purchaseBatchDetailList: { type: Array, defualt: () => [] },
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          total: '',
          detailList: []
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {
        total: '',
        detailList: []
      },
      searchText: '',
      step: 0,
      modal: false,
      loading: false
    }
  },
  computed: {
    methodTypeList () {
      let data = {
        mini: ['询价', '单一来源采购', '竞争性谈判'],
        small: ['询价', '单一来源采购', '竞争性谈判'],
        big: ['公开招标', '询价', '邀请招标', '单一来源采购']
      }
      return data
    },
    showPurchaseBatchDetail () {
      let allData = this.purchaseBatchDetailList
      if (this.searchText) {
        allData = allData.filter(item => (item.code + ' ' + (item.orgName || '') + ' ' + item.goodsName).includes(this.searchText))
      }
      return allData
    }
  },
  methods: {
    onChange (key) {
      if (isNaN(this.formData[key])) {
        this.$notify({
          title: '系统提示',
          message: '请输入数值',
          type: 'warning'
        })
        this.formData[key] = ''
        this.$set(this.formData, 'total', 0)
        return
      }
      if (this.formData.num && this.formData.price && !isNaN(this.formData.num) && !isNaN(this.formData.price)) {
        this.$set(this.formData, 'total', Number((this.formData.num * this.formData.price).toFixed(2)))
      } else {
        this.$set(this.formData, 'total', 0)
      }
    },
    async formSubmit () {
      if (this.loading) {
        return
      }
      let detailList = JSON.parse(JSON.stringify(this.$refs.detailList.data))
      detailList.forEach(v => {
        if (typeof v.id === 'string') {
          v.id = null
        }
      })
      detailList = detailList.filter(v => v.id || !v.isDel)
      if (detailList.length === 0) {
        this.$notify({
          title: '系统提示',
          message: '请选择采购明细',
          type: 'info'
        })
        return
      }
      if (!this.formData.busOrgId) {
        if (this.orgList.length === 1) {
          this.formData.busOrgId = this.orgList[0].key
        } else {
          this.$notify({
            title: '系统提示',
            message: '请选择职能科室',
            type: 'info'
          })
          return
        }
      }
      if (!this.formData.type) {
        this.$notify({
          title: '系统提示',
          message: '请选择类型',
          type: 'info'
        })
        return
      }
      if (!this.formData.method) {
        this.$notify({
          title: '系统提示',
          message: '请选择采购方式',
          type: 'info'
        })
        return
      }
      this.loading = true
      let data = Object.assign({}, this.formData, {
        detailList
      })
      try {
        if (data.id) {
          await purchaseMethodBatchDetailsRequest.update(data.id, data)
        } else {
          await purchaseMethodBatchDetailsRequest.add(data)
        }
        this.$emit('dataUpdate', 'add')
        this.modal = false
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
  }
  .left-a {
    flex: 1;
    height: 100%;
  }
  .right {
    margin-right: 20px;
    width: 400px;
  }
  .purchase-batch-detail-list-search {
    margin-left: 10px;
  }
  .purchase-batch-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .purchase-batch-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .purchase-batch-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.xj {
  position: absolute;
  right: 20px;
  bottom: 0px;
  color: #F4628F;
}
</style>