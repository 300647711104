<template>
  <div class="purchase-method-detail-list">
    <div class="detail-info info-block" v-for="detail in data.filter(v => !v.isDel)" :key="detail.id">
      <div class="info-item">
        <div class="label">采购物品：</div>
        <div class="value">{{detail.purchaseBatchDetail.goodsName}}</div>
      </div>
      <div class="info-item">
        <div class="label">单价：</div>
        <div class="value">{{detail.price == null ? '' : (detail.price + '元')}}</div>
      </div>
      <div class="info-item">
        <div class="label">数量：</div>
        <div class="value">{{detail.num == null ? '' : detail.num}}</div>
      </div>
      <div class="info-item">
        <div class="label">总价：</div>
        <div class="value">{{detail.total == null ? '' : (detail.total + '元')}}</div>
      </div>
      <div class="info-item">
        <div class="label">可选品牌及规格型号：</div>
        <div class="value">{{detail.typeDetail}}</div>
      </div>
      <div class="info-item" style="width: 100%;border-top: 1px solid #e8eaec;padding-top: 10px;">
        <div>
          关联采购明细：{{detail.purchaseBatchDetail.code}}
        </div>
      </div>
      <div class="info-item">
        <div class="label">申报部门：</div>
        <div class="value">{{detail.purchaseBatchDetail.orgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请人：</div>
        <div class="value">{{detail.purchaseBatchDetail.userName}}</div>
      </div>
      <div class="info-item">
        <div class="label">项目名称：</div>
        <div class="value">{{detail.purchaseBatchDetail.goodsName}}</div>
      </div>
      <div class="info-item">
        <div class="label">可选品牌及规格型号：</div>
        <div class="value">{{detail.purchaseBatchDetail.typeDetail}}</div>
      </div>
      <div class="info-item">
        <div class="label">单价：</div>
        <div class="value">{{detail.purchaseBatchDetail.price + '元'}}</div>
      </div>
      <div class="info-item">
        <div class="label">数量：</div>
        <div class="value">{{detail.purchaseBatchDetail.num}}</div>
      </div>
      <div class="info-item">
        <div class="label">总价：</div>
        <div class="value">{{detail.purchaseBatchDetail.total + '元'}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请理由：</div>
        <div class="value">{{detail.purchaseBatchDetail.reason}}</div>
      </div>
      <div class="btns">
        <fm-btn size="small" @click="chooseData = JSON.parse(JSON.stringify(detail));openDialog=true;" style="margin-right: 50px;">修改</fm-btn>
        <fm-btn size="small" @click="del(detail.id)">删除</fm-btn>
      </div>
    </div>
    <div class="assets-item">
      <fm-btn size="small" @click="chooseData = null;openDialog=true;">
        添加明细
      </fm-btn>
    </div>
    <fm-form-dialog
      form-title="采购明细"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      :mask-closable="false"
      :on-change="onFormChange"
      :submit-btn-label="chooseData ? '修改' : '添加'"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
export default {
  props: {
    detailList: { type: Array, defualt: () => [] },
    purchaseBatchDetailList: { type: Array, defualt: () => [] }
  },
  data () {
    return {
      data: [],
      chooseData: null,
      openDialog: false
    }
  },
  watch: {
    detailList: {
      deep: true,
      handler () {
        this.data = this.detailList ? JSON.parse(JSON.stringify(this.detailList)) : []
      },
      immediate: true
    }
  },
  computed: {
    formParms () {
      return [{
        label: '采购申请明细',
        key: 'purchaseBatchDetailId',
        type: 'select',
        selectDatas: this.purchaseBatchDetailList.map(v => {
          return {
            key: v.id,
            label: v.code + ' ' + (v.orgName || '') + ' ' + v.goodsName
          }
        }),
        check: {
          required: true,
        }
      },
      {
        label: '单价',
        key: 'price',
        type: 'input',
        show (data) {
          return data.purchaseBatchDetail
        },
        check: {
          rules: {
            type: 'number'
          }
        }
      },
      {
        label: '数量',
        key: 'num',
        type: 'input',
        show (data) {
          return data.purchaseBatchDetail
        },
        check: {
          rules: {
            type: 'number'
          }
        }
      },
      {
        label: '总价',
        key: 'total',
        type: 'input',
        show (data) {
          return data.purchaseBatchDetail
        },
        check: {
          rules: {
            type: 'number'
          }
        }
      },
      {
        label: '可选品牌及规格型号',
        key: 'typeDetail',
        type: 'input',
        show (data) {
          return data.purchaseBatchDetail
        }
      }]
    }
  },
  methods: {
    formSubmit (data, r) {
      if (data.purchaseBatchDetail.maxPrice && data.purchaseBatchDetail.maxPrice < data.price) {
        this.$notify({
          title: '系统提示',
          message: '该物品限价' + data.purchaseBatchDetail.maxPrice +'元',
          type: 'warning'
        })
        r()
        return
      }
      if (data.id) {
        this.data = this.data.map(v => {
          return v.id === data.id ? data : v
        })
      } else {
        data.id = data.id || ('id_' + new Date().getTime())
        this.data.push(data)
      }
      this.openDialog = false
      r()
    },
    onFormChange (a, b, c) {
      if (a === 'purchaseBatchDetailId') {
        if (b) {
          let purchaseBatchDetail = this.purchaseBatchDetailList.find(v => v.id === b)
          return {
            purchaseBatchDetail,
            num: purchaseBatchDetail.num,
            price: purchaseBatchDetail.price,
            total: purchaseBatchDetail.total,
            typeDetail: purchaseBatchDetail.typeDetail
          }
        } else {
          return {
            purchaseBatchDetail: null
          }
        }
      } else if (a === 'price' || a === 'num') {
        if (a === 'price' && !isNaN(c.price) && c.purchaseBatchDetail && c.purchaseBatchDetail.maxPrice && c.purchaseBatchDetail.maxPrice < Number(c.price)) {
          this.$notify({
            title: '系统提示',
            message: '该物品限价' + c.purchaseBatchDetail.maxPrice +'元',
            type: 'warning'
          })
          return
        }
        if (!isNaN(c.price) && !isNaN(c.num)) {
          return {
            total: Number(c.price) * Number(c.num)
          }
        }
      }
    },
    add (purchaseBatchDetail) {
      this.data.push({
        purchaseBatchDetail,
        purchaseBatchDetailId: purchaseBatchDetail.id,
        num: purchaseBatchDetail.num,
        price: purchaseBatchDetail.price,
        total: purchaseBatchDetail.total,
        typeDetail: purchaseBatchDetail.typeDetail
      })
    },
    del (id) {
      this.data = this.data.map(v => {
        if (v.id === id) {
          v.isDel = true
        }
        return v
      })
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-list {
  .info-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .detail-info {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e8eaec;
    border-radius: 5px;
  }
  .info-item {
    display: flex;
    padding-right: 50px;
    margin-bottom: 5px;
    .label {
      margin-right: 0px;
    }
  }
  .btns {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}
</style>